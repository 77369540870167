import React from "react";

const Wave = () => {
  return (
    <>
      <svg
        width="100%"
     
        id="svg"
        viewBox="0 0 1440 445"
        xmlns="http://www.w3.org/2000/svg"
        class="transition duration-300 ease-in-out delay-150"
      >
        <path
          d="M 0,700 C 0,700 0,350 0,350 C 73.09090909090907,352.25837320574163 146.18181818181813,354.51674641148327 251,381 C 355.81818181818187,407.48325358851673 492.3636363636364,458.1913875598086 600,460 C 707.6363636363636,461.8086124401914 786.3636363636364,414.7177033492823 865,373 C 943.6363636363636,331.2822966507177 1022.1818181818182,294.9377990430622 1118,292 C 1213.8181818181818,289.0622009569378 1326.909090909091,319.5311004784689 1440,350 C 1440,350 1440,700 1440,700 Z"
          stroke="none"
          stroke-width="0"
          fill="#701a75"
          fill-opacity="1"
          class="transition-all duration-300 ease-in-out delay-150 path-0"
          transform="rotate(-180 720 350)"
        ></path>
      </svg>
    </>
  );
};

export default Wave;
